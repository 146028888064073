<template>
    <v-container fluid>
        <v-card>
            <v-card-title>
                {{ pageTitle }}
            </v-card-title>

            <app-table
                v-if="isEmpty(table)"
                :key="appTableKey"
                :table-schema="tableSchema"
                :filter="filter"
                :form="form" />
            <component
                :is="table"
                v-else
                :key="appTableKey"
                :table-schema="tableSchema"
                :filter="filter"
                :form="form" />
        </v-card>
    </v-container>
</template>

<script>
export default {
    components: {
        appTable: () => import("./AppTable.vue"),
    },
    props: {
        filter: {
            type: Object,
            default: null,
        },
        pageTitle: {
            type: String,
            default: null,
        },
        form: {
            type: Function,
            default: null
        },
        table: {
            type: Function,
            default: null
        }
    },

    data() {
        return {
            tableSchema: {},
            appTableKey: 0,
        };
    },
    watch: {
        pageTitle: {
            immediate: true,
            handler() {
                this.initialize();
            },
        },
    },

    methods: {
        initialize() {
            this.tableSchema = this.getTableSchema(this.pageTitle);
            this.appTableKey++;
        },
    },
};
</script>
